import type { EventType } from '@pidk/api/src/types/api'

const STORAGE_KEY = 'edg-pidk-session-events'

interface IEvent {
  type: EventType
  deckId: string
}

export const getEventsFromStorage = (): IEvent[] => {
  const eventObject = sessionStorage.getItem(STORAGE_KEY)

  return JSON.parse(eventObject) || []
}

export const hasSentEvent = (event: IEvent): boolean => {
  const events = getEventsFromStorage()
  return events.some((e: IEvent) => e.type === event.type && e.deckId === event.deckId)
}

export const addEventToStorage = (event: IEvent) => {
  const events = getEventsFromStorage()
  events.push(event)
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(events))
}

export const removeEventsFromStorage = () => {
  sessionStorage.removeItem(STORAGE_KEY)
}
