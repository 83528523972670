import type { Project } from '@pidk/compose/src/types/compose'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'

import type { IRendererContext, OnEventCreate, OnScopedEventCreateBody, RendererState } from '../contexts/renderer'
import { addEventToStorage, hasSentEvent } from '../utils/events'
import { getProjectWithDefaultTheme } from '../utils/project'

export type IRendererContextProps = Omit<IRendererContext, 'rendererState' | 'setRendererState' | 'onScopedEventCreate'> & {
  onEventCreate?: OnEventCreate
}

const getProjectWithSortedSlides = (project: Project): Project => {
  return {
    ...project,
    decks: project.decks.map(d => {
      return {
        ...d,
        slides: d.slides.sort((a, b) => a.order - b.order)
      }
    })
  }
}

const useRendererContextProvider = ({
  isPreview,
  isAuthenticated,
  onNavigate,
  project,
  currentDeckId,
  currentSlideId,
  onAreaClick,
  selectedAreaId,
  isEditable,
  onError,
  socketUrl,
  onProjectLogin,
  onRoomCreate,
  onRoomCreateInteraction,
  onRoomGetInteractionResults,
  onRoomDeactivate,
  onRoomActivate,
  onRoomClearLastInteraction,
  onEventCreate
}: IRendererContextProps) => {
  const [rendererState, setRendererState] = useState<RendererState>({
    eventSessionId: undefined,
    isAuthenticated,
    isConnected: false,
    hasStarted: false,
    hasFinished: false,
    hasAskedForStartLesson: false,
    viewMode: undefined,
    checkoutCallback: undefined
  })

  const [_project, setProject] = useState<Project>(() => {
    if (project?.decks) {
      return getProjectWithSortedSlides(project)
    }

    return project
  })

  useUpdateEffect(() => {
    setRendererState(prevState => ({
      ...prevState,
      isAuthenticated
    }))
  }, [isAuthenticated])

  useUpdateEffect(() => {
    if (project?.decks) {
      return setProject(getProjectWithSortedSlides(project))
    }

    return setProject(project)
  }, [project])

  return {
    setProjectState: project => {
      project.theme = JSON.parse(project.theme)

      setProject(
        getProjectWithDefaultTheme(project)
      )
    },
    onScopedEventCreate: async (body: OnScopedEventCreateBody) => {
      const event = { type: body.type, deckId: body.deckId }

      // Ensure each unique event is only sent once in a session
      if (hasSentEvent(event)) return

      const eventFormatted = await onEventCreate({
        projectId: _project.id,
        sessionId: rendererState.eventSessionId,
        ...body,
        meta: JSON.stringify(body.meta)
      })
      addEventToStorage(event)
      return eventFormatted
    },
    onNavigate,
    isPreview,
    project: _project,
    currentDeckId,
    currentSlideId,
    rendererState,
    setRendererState,
    selectedAreaId,
    onAreaClick,
    isEditable,
    onError,
    socketUrl,
    onProjectLogin,
    onRoomCreate,
    onRoomCreateInteraction,
    onRoomGetInteractionResults,
    onRoomDeactivate,
    onRoomActivate,
    onRoomClearLastInteraction
  }
}

export default useRendererContextProvider
